import React, { useState, useEffect } from 'react';
import 'App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Rent from './Rent';
import Buy from './Buy';
import BaseParameters from './BaseParameters';
import GrandTotal from '../GrandTotal';
import Instructions from '../Instructions';

const RentBuy = ({ setTitle }) => {
  const [rentBest, setRentBest] = useState(false);
  const [buyBest, setBuyBest] = useState(false);
  const [rentMonthlyBest, setRentMonthlyBest] = useState(false);
  const [buyMonthlyBest, setBuyMonthlyBest] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [totalRent, setTotalRent] = useState(0);
  const [totalBuy, setTotalBuy] = useState(0);
  const [years, setYears] = useState(0);
  const [lifeExpectancy, setLifeExpectancy] = useState(90);
  const [age, setAge] = useState(30);

  const [monthlyBuy, setMonthlyBuy] = useState(0);
  const [monthlyRent, setMonthlyRent] = useState(0);

  useEffect(() => {
    setTitle("Rent Or Buy (Principle Place of Residence)");
    return () => {
      setTitle();
    };
  }, [setTitle]);

  useEffect(() => {
    setYears(lifeExpectancy - age);
  }, [lifeExpectancy, age]);


  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleYearsChange = (event) => {
    setYears(parseFloat(event.target.value));
    calculate()
  }

  const handleLifeExpectancy = (event) => setLifeExpectancy(parseFloat(event.target.value));
  const handleAgeChange = (event) => setAge(parseFloat(event.target.value));

  const handleRentGrandTotalChange = (value) => {
    setTotalRent(value)
    calculate()
  }

  const handleBuyGrandTotalChange = (value) => {
    setTotalBuy(value)
    calculate()
  }

  const handleRentMonthlyTotalChange = (value) => {
    setMonthlyRent(value)
  }

  const handleBuyMontlyTotalChange = (value) => {
    setMonthlyBuy(value)
  }

  const calculate = () => {
    setRentBest(totalRent < totalBuy);
    setBuyBest(totalBuy < totalRent);

    setRentMonthlyBest(monthlyRent < monthlyBuy);
    setBuyMonthlyBest(monthlyBuy < monthlyRent);
  }

  return (
    <Container className="Calc">
      <h1 className="calc-header">Rent a house or Buy one</h1>
      <Instructions handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} showModal={showModal} />
      <BaseParameters years={years} age={age} lifeExpectancy={lifeExpectancy}
        changeLength={handleYearsChange} changeAge={handleAgeChange} changeLifeExpectancy={handleLifeExpectancy} />
      <Row>
        <Col sm={6} className={`d-flex flex-column grand-total-narrow ${rentBest === true ? 'best' : ''} choices-app`}>
          <Rent years={years} total={totalRent} onGrandTotalChange={handleRentGrandTotalChange} onGrandTotalMonthlyChange={handleRentMonthlyTotalChange} />
          <div className='footer'>
            <p className='grandTotalHeading'>Total Cost</p>
            <GrandTotal total={totalRent} best={rentBest} />
            <p className='grandTotalHeading' style={{ 'margin-top': '0.5em' }}>Monthly Cost</p>
            <GrandTotal total={monthlyRent} best={rentMonthlyBest} />
          </div>
        </Col>
        <Col sm={6} className={`d-flex flex-column grand-total-narrow ${buyBest === true ? 'best' : ''} choices-app`}>
          <Buy onGrandTotalChange={handleBuyGrandTotalChange} years={years} onGrandTotalMonthlyChange={handleBuyMontlyTotalChange}
          />
          <div className='footer'>
            <p className='grandTotalHeading'>Total Cost</p>
            <GrandTotal total={totalBuy} best={buyBest} />
            <p className='grandTotalHeading' style={{ 'margin-top': '0.5em' }}>Monthly Cost (incl. ongoing budgeting)</p>
            <GrandTotal total={monthlyBuy} best={buyMonthlyBest} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RentBuy;