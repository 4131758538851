import React, { useState, useEffect } from 'react';
import InputLabelGroup from 'InputLabelGroup';
import SelectLabelGroup from 'SelectLabelGroup';
import Form from 'react-bootstrap/Form';

function Rent(props) {

    const [rentIncrease, setRentIncrease] = useState(5);
    const [carpetCleaning, setCarpetCleaning] = useState(500);
    const [removalCosts, setRemovalCosts] = useState(500);
    const [rent, setRent] = useState(310);

    const handleRentChange = (event) => setRent(parseFloat(event.target.value));
    const handleRentIncrease = (event) => setRentIncrease(parseFloat(event.target.value));

    const handleCarpetCleaningChange = (event) => setCarpetCleaning(parseFloat(event.target.value));
    const handleRemovalCostsChange = (event) => setRemovalCosts(parseFloat(event.target.value));

    useEffect(() => {
        let newRentTotal = rent * 52 * Math.pow(props.years, (1 + rentIncrease / 100));
        props.onGrandTotalChange(newRentTotal);
        props.onGrandTotalMonthlyChange(rent * 4.25);
    })

    return (
        <Form>
            <InputLabelGroup label="Rent per week" type="text" controlId="rentAmount" onChange={handleRentChange} value={rent} />
            <InputLabelGroup label="Annual rate of rent increase" type="text" controlId="increaseRate" value={rentIncrease} onChange={handleRentIncrease} />
            {/* <InputLabelGroup label="Carpet cleaning cost" type="text" controlId="carpetCleaning" value={carpetCleaning} onChange={handleCarpetCleaningChange} />
            <InputLabelGroup label="Removal cost" type="text" controlId="movalCost" value={removalCosts} onChange={handleRemovalCostsChange} />
            <SelectLabelGroup label="How often do you move to a new place?" controlId="movingFreq">
                <option value="1">Once a year</option>
                <option value="3">Every three years</option>
                <option value="5">Every five years</option>
                <option value="10">Every 10 years</option>
            </SelectLabelGroup> */}
            <div>Total: {props.total.toLocaleString({ style: 'currency' })}</div>
        </Form>

    );
}

export default Rent;