import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import ApartmentHotel from './ApartmentHotel/ApartmentHotel';
import RentBuy from './RentBuy/RentBuy';
import UnitCostAlternatives from './UnitCostAlternatives/UnitCostAlternatives';
import UnitCostBudgeting from './UnitCostBudgets/UnitCostBudgeting';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class App extends Component {
  constructor() {
    super();
    this.state = { title: "" }

    this.setTitle = this.setTitle.bind(this);
  }

  setTitle(newTitle) {
    this.setState({ title: newTitle });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header"><span><Link to='/'>Calculate Decisions</Link></span><span>{this.state.title}</span></header>
          <Row>
            <Col xs={12} sm={3}>

              <nav className='main'>
                <h1>Decisions</h1>
                <ul>
                  <li><Link to='/apartment-hotel' state={{ title: "value" }}>Hotel vs Serviced Apartment?</Link></li>
                  <li><Link to='/rent-buy'>Rent vs Buy Home?</Link></li>
                  <li><Link to='/unit-cost-alternatives' state={{ title: "value" }}>Unit Cost Alternatives</Link></li>
                  <li><Link to='/unit-cost-budgeting' state={{ title: "value" }}>Unit Cost Budgeting</Link></li>
                </ul>
              </nav>

            </Col>
            <Col className='app-side'>
              <Routes>
                <Route path='/rent-buy' element={<RentBuy setTitle={this.setTitle} />} />
                <Route path='/apartment-hotel' element={<ApartmentHotel setTitle={this.setTitle} />} />
                <Route path='/unit-cost-alternatives/*' element={<UnitCostAlternatives setTitle={this.setTitle} />} />
                <Route path='/unit-cost-budgeting/*' element={<UnitCostBudgeting setTitle={this.setTitle} />} />
              </Routes>
            </Col>
          </Row>

        </div>
      </Router>
    );
  }
}

export default App;
