import React from 'react';

function InputLabelGroup(props) {

  return (
    <div className={`grand-total ${props.best === true ? 'best' : ''} `}>
      <span>{props.total.toLocaleString({ style: 'currency' })}</span>
    </div>
  )
}

export default InputLabelGroup;